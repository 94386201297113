import React from "react"
import { Table, Tr, Td } from "./TableElements"
import ButtonEdit from "../Elements/ButtonEdit"
import ButtonDelete from "../Elements/ButtonDelete"

const tdClassName =
  "align-center max-w-[200px] text-reg-14 overflow-hidden truncate"

const mapDataArrayToCell = data => {
  return data.map((v, k) => {
    if (k === data.length - 1 && k !== 0) {
      return (
        <>
          <br />
          {" and " + v.title}
        </>
      )
    }
    if (k != 0) {
      return (
        <>
          {", "}
          <br />
          {v.title}
        </>
      )
    }
    return <>{v.title}</>
  })
}

export default function ManageUserGroupsTable({
  data,
  onEditClick,
  onDeleteClick,
}) {
  return (
    <Table
      columns={[
        "ID",
        "Name",
        "Regions",
        "User",
        "Stores",
        "Companies",
        "Active",
        "Action",
      ]}
    >
      {data.map((v, k) => (
        <Tr key={k}>
          <Td>
            <div className="flex items-center">{v.id}</div>
          </Td>
          <Td className={tdClassName}>
            <div className="text-sm text-gray-900 truncate">{v.title}</div>
          </Td>
          <Td className={tdClassName}>
            {mapDataArrayToCell(v.field_group_regions)}
          </Td>
          <Td className={tdClassName}>
            {mapDataArrayToCell(v.field_group_users)}
          </Td>
          <Td className={tdClassName}>
            {mapDataArrayToCell(v.field_group_stores)}
          </Td>
          <Td className={tdClassName}>
            {mapDataArrayToCell(v.field_group_companies)}
          </Td>
          <Td className={tdClassName}>
            {v.field_active === "1" ? "Active" : "Inactive"}
          </Td>
          <Td className="align-top">
            <div className="flex flex-col space-y-3">
              <ButtonEdit text="Edit" onClick={() => onEditClick(v.id)} />
              {/*<ButtonDelete text="Delete" onClick={() => onDeleteClick(v.id)} />*/}
            </div>
            {/*<button
              className="text-indigo-600 hover:text-indigo-900"
              onClick={() => onEditClick(v.id)}
            >
              Edit
            </button>
            <button
              onClick={onDeleteClick}
              className="text-indigo-600 hover:text-red-600"
            >
              Delete
            </button>*/}
          </Td>
        </Tr>
      ))}
    </Table>
  )
}
