import React, { useState, useEffect, useContext } from "react"
import LayoutDashborad from "../../components/Layout/Dashboard"
import Pagination from "../../components/Pagination"
import Modal from "../../components/Modal/Modal"
import {
  getMyListing,
  getMyListingPagination,
  getMySingleForm,
} from "../../helpers/DrupalHelper"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import StateContext from "../../context/state/StateContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import ManageUserGroupsTable from "../../components/Table/ManageUserGroupsTable"
import NotificationContext from "../../context/Notification/NotificationContext"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import Button from "../../components/Elements/Button"
import Skeleton from "../../components/Skeleton"
import AccessDenied from "../../components/AccessDenied"
import DataContext from "../../context/Data/DataContext"
function ManageUserGroups() {
  dashboardTrail.push({ text: "User Segmentation", link: "#" })
  const [list, setList] = useState(null)
  const { state } = useContext(StateContext)
  const { userType } = useContext(AuthenticationContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { updateState } = useContext(DataContext)
  const [open, setOpen] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)

  const [editCurrent, setEditCurrent] = useState(null)
  const [formData, setFormData] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [perPage, setPerPage] = useState(20)
  useEffect(async () => {
    let total = await getMyListingPagination(
      state.token,
      "user_group",
      "default"
    )
    setTotal(total)

    let pages = Math.ceil(total / perPage)
    setTotalPage(pages)
  }, [])
  useEffect(() => {
    let pages = Math.ceil(total / perPage)
    setTotalPage(pages)
  }, [perPage])
  const onPageChange = async v => {
    setPageNumber(v)
    const newPageData = await getMyListing(
      state.token,
      "user_group",
      "default",
      v
    )
    setList([...newPageData])
  }

  const onEditClick = async v => {
    setEditCurrent(v)

    setOpen(true)
  }
  const onSave = async (data, v) => {
    if (v === "added") {
      const datas = await getMyListing(state.token, "user_group")
      setList(datas)
      setOpen(false)
      setOpenCreate(false)
      toggleNotification({ content: "Your User Segment was created successfully!" })
      updateState("myUserGroups", datas)
    } else if (v === "edited") {
      const datas = await getMyListing(state.token, "user_group")
      setList(datas)
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: "Your User Segment was edited successfully!" })
      updateState("myUserGroups", datas)
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: "Something failed, sorry about that.", error: true })
    }
  }
  useEffect(async () => {
    let data = await getMyListing(state.token, "user_group")
    setList([...data])
    let formData = await getMySingleForm(state.token, "user_group")
    setFormData(formData)
  }, [])
  if (!userType.brandManager) return <AccessDenied />
  return (
    <LayoutDashborad heading="User Segmentation" trail={dashboardTrail}>
      <div className="mb-[30px]">
        <Button red widthAuto onClick={() => setOpenCreate(true)}>
          Create New User Segment
        </Button>
      </div>
      {list === null ? (
        <Skeleton table />
      ) : list.length > 0 ? (
        <ManageUserGroupsTable
          onEditClick={onEditClick}
          data={list}
        />
      ) : (
        "No items to display"
      )}

      <Pagination
        page={pageNumber}
        setPage={v => onPageChange(v)}
        totalPages={totalPage}
      />
      <Modal
        title={"Create New User Segment"}
        subtitle={"Give your User Segment a name, select your Company, select only one Type, and add either the Users, Regions, Stores or Companies to be included."}
        isOpen={openCreate}
        closeModal={() => {
          setOpenCreate(false)
        }}
      >
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="user_group"
            bundle="default"
          />
        )}
      </Modal>
      {open && editCurrent && (
        <Modal
          isOpen={open}
          closeModal={() => {
            setEditCurrent(null)
            setOpen(false)
          }}
          title={"Edit User Segment"}
          subtitle={"Give your User Segment a name, select your Company, select only one Type, and add either the Users, Regions, Stores or Companies to be included."}
        >
          <EditFormTemplate
            edit
            id={editCurrent}
            token={state.token}
            bundle="default"
            name={"user_group"}
            list={list}
            onSave={onSave}
          />
        </Modal>
      )}
    </LayoutDashborad>
  )
}
export default ManageUserGroups
